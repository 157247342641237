// table keys
export const DONT_SHOW_FOLLOW_UP_TASK = 'Tasks:Queues:FollowUpTasks:hide';

// task defaults keys
export const DEFAULT_DUE_DATE_PRESET = 'Tasks:Defaults:DueDatePreset';
export const DEFAULT_DUE_TIME = 'Tasks:Defaults:DueTime';
export const DEFAULT_REMINDER_PRESET = 'Tasks:Defaults:ReminderPreset';
export const TASKS_USER_SETTINGS = [DONT_SHOW_FOLLOW_UP_TASK, DEFAULT_DUE_DATE_PRESET, DEFAULT_DUE_TIME, DEFAULT_REMINDER_PRESET];

// local storage keys
export const TASK_TABLE_PAGE_SIZE = 'gridPageSize';
export const TASKS_LOCAL_STORAGE_USER_SETTINGS = [TASK_TABLE_PAGE_SIZE];